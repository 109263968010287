import { navigate } from './nav';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import {
  MEMBERS_LIST,
  SIMILAR_COMPANIES_DATA,
  SIMILAR_COMPANIES_CHECK,
  REGISTER_COMPANY_PAYLOAD,
  // VOUCHER_TYPE_PURCHASE,
  // IS_BASIC_TYPE,
  ERROR,
  OTHER_CHARGES,
  OTHER_CHARGES_AFTER_TAX,
  EXPENSE,
  RP_VOUCHER,
  CURRENT_COMPANY_TOKEN,
  PARTY_LIST_DATA,
  INDIA,
  VOUCHER_SETTINGS_LIST,
  VOUCHER_DEFAULT_COLUMN_VISIBILITY,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_PAYMENT,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  VOUCHER_TYPE_CONTRA,
  VOUCHER_TYPE_JOURNAL,
  VOUCHER_TYPE_EXPENSE
} from '../constants';

import {
  CHANGE_COMPANY,
  COMPANY_AUTH_FAILED,
  COMPANY_AUTH_SUCCESS,
  COMPANY_SIGNOUT,
  COMPANIES_LIST_FAILED,
  COMPANIES_LIST_SUCCESS,
  COMPANY_BRANCH_FAILURE,
  COMPANY_BRANCH_SUCCESS,
  COMPANY_AUTH_REQUESTED,
  COMPANY_MEMBERS_FAILED,
  COMPANY_MEMBERS_SUCCESS,
  COMPANIES_LIST_REQUESTED,
  COMPANY_BRANCH_REQUESTED,
  COMPANY_MEMBERS_REQUESTED,
  ADD_COMPANIES_LIST,
  COMPANY_BRANCH_UPDATE,
  COMPANY_ADD_BRANCH_REQUESTED,
  COMPANY_ADD_BRANCH_SUCCESS,
  COMPANY_ADD_BRANCH_FAILED,
  COMPANY_UPDATE_BRANCH_REQUESTED,
  COMPANY_UPDATE_BRANCH_SUCCESS,
  COMPANY_UPDATE_BRANCH_FAILED,
  COMPANY_ADD_BANK_ACCOUNT_REQUESTED,
  COMPANY_ADD_BANK_ACCOUNT_SUCCESS,
  COMPANY_ADD_BANK_ACCOUNT_FAILED,
  COMPANY_UPDATE_BANK_ACCOUNT_REQUESTED,
  COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS,
  COMPANY_UPDATE_BANK_ACCOUNT_FAILED,
  COMPANY_PASSWORD_CHANGE_REQUESTED,
  COMPANY_PASSWORD_CHANGE_SUCCESS,
  COMPANY_PASSWORD_CHANGE_FAILED,
  COMPANY_FETCH_ROLE_CONFIG_SUCCESS,
  COMPANY_FETCH_ROLE_CONFIG_FAILED,
  COMPANY_ROLE_CONFIG_REQUESTED,
  COMPANY_ADD_MEMBER_ROLE_REQUESTED,
  COMPANY_ADD_MEMBER_ROLE_SUCCESS,
  COMPANY_ADD_MEMBER_ROLE_FAILED,
  COMPANY_REMOVE_MEMBER_ROLE_REQUESTED,
  COMPANY_REMOVE_MEMBER_ROLE_SUCCESS,
  COMPANY_REMOVE_MEMBER_ROLE_FAILED,
  COMPANY_FETCH_TEAM_ROLE_SUCCESS,
  COMPANY_FETCH_TEAM_ROLE_FAILED,
  COMPANY_UPDATE_DETAILS_REQUESTED,
  COMPANY_UPDATE_DETAILS_SUCCESS,
  COMPANY_UPDATE_DETAILS_FAILED,
  COMPANY_DELETE_BRANCH_REQUESTED,
  COMPANY_DELETE_BRANCH_SUCCESS,
  COMPANY_DELETE_BRANCH_FAILED,
  COMPANY_DELETE_BANK_ACCOUNT_REQUESTED,
  COMPANY_DELETE_BANK_ACCOUNT_SUCCESS,
  COMPANY_DELETE_BANK_ACCOUNT_FAILED,
  COMPANY_FETCH_TEAM_REQUESTED,
  COMPANY_FETCH_TEAM_SUCCESS,
  COMPANY_FETCH_TEAM_FAILED,
  COMPANY_FETCH_TEAM_ROLE_REQUESTED,
  COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS,
  COMPANY_FETCH_ACCOUNT_GROUP_FAILED,
  COMPANY_ADD_ACCOUNT_REQUESTED,
  COMPANY_ADD_ACCOUNT_SUCCESS,
  // COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS,
  // COMPANY_FETCH_ALL_ACCOUNTS_FAILED,
  COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED,
  COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS,
  COMPANY_FETCH_BANK_ACCOUNTS_FAILED,
  COMPANY_RESET_ROLES_REQUESTED,
  COMPANY_RESET_ROLES_SUCCESS,
  COMPANY_RESET_ROLES_FAILED,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILED,
  COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS,
  COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_FAILED,
  COMPANY_ADD_ACCOUNT_FIELD_METADATA,
  ADD_EXPENSE_LINE_ITEM,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILED,
  FETCH_STATES_REQUESTED,
  COMPANY_DELETE_TEAM_MEMBER_REQUESTED,
  COMPANY_DELETE_TEAM_MEMBER_SUCCESS,
  COMPANY_DELETE_TEAM_MEMBER_FAILED,
  FETCH_COMPANY_TEMPLATE_LIST,
  PDF_TEMPLATES_REQUESTED,
  PDF_TEMPLATES_SUCCESS,
  CHANGE_FINANCIAL_YEAR,
  FETCH_INDUSTRY_TYPES_REQUESTED,
  FETCH_INDUSTRY_TYPES_SUCCESS,
  FETCH_INDUSTRY_TYPES_FAILED,
  ACCOUNT_SEARCH_REQUESTED,
  ACCOUNT_SEARCH_SUCCESS,
  ACCOUNT_SEARCH_FAILED,
  FETCH_CITIES_REQUESTED,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILED,
  FETCH_PARTIES_STATES_REQUESTED,
  FETCH_PARTIES_STATES_SUCCESS,
  FETCH_PARTIES_STATES_FAILED,
  FETCH_PARTY_ACCOUNT_TYPES_REQUESTED,
  FETCH_PARTY_ACCOUNT_TYPES_SUCCESS,
  FETCH_PARTY_ACCOUNT_TYPES_FAILED,
  ACCOUNT_FORM_REQUESTED,
  ACCOUNT_FORM_FAILED,
  ACCOUNT_FORM_SUCCESS,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED,
  COMPANY_DELETE_USER_SESSION_REQUESTED,
  COMPANY_DELETE_USER_SESSION_SUCCESS,
  COMPANY_DELETE_USER_SESSION_FAILED,
  VOUCHER_DATE_RANGE,
  UPDATE_USER_COMPANY_SETTING,
  ADD_RP_ACCOUNT,
  SET_FILTER_DATES,
  PROGRESS_TRACKER,
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILED,
  FETCH_ALL_BRANCHES_SUCCESS,
  SET_CACHE_API_LIST,
  RESET_ALL_COLUMN_VISIBILITY,
  FETCH_PROGRESS_TRACKER_LIST_SUCCESS,
  FETCH_PROGRESS_TRACKER_LIST_FAILED,
  FETCH_PROGRESS_TRACKER_LIST,
  COMPANY_SET_E_SHOP_STATUS_REQUESTED,
  COMPANY_SET_E_SHOP_STATUS_SUCCESS,
  COMPANY_SET_E_SHOP_STATUS_FAILED,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED,
  COMPANY_UPDATE_E_SHOP_NAME_REQUESTED,
  COMPANY_UPDATE_E_SHOP_NAME_SUCCESS,
  COMPANY_UPDATE_E_SHOP_NAME_FAILED,
  SET_PROGRESS_STATUS_BY_ID,
  TOGGLE_BRANCH_SETTING_MODAL,
  UPDATE_USER_COMPANY_VOUCHER_SETTING,
  FETCH_COMPANY_SETTINGS_SUCCESS,
  UPDATE_USER_PROFILE_PICTURE_REQUESTED,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  UPDATE_USER_PROFILE_PICTURE_FAILED,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
  POS_BILLING_PARTY,
  SET_ITEM_BULK_SETTING,
  HANDLE_PARTY_CREATION_MODE,
  SET_COMPANY_VOUCHER_SETTINGS
} from './types';

import * as api from '../api/companies';
import { setCompanySessionToken, serverError, setCompanyPdfToken } from '../api/server';
import { toggleModalView, handleFetchAccountCard } from './contacts';
import { updateAccount, updateAccountField } from './vouchers';
import { openSnackbar } from './snackbar';
import {
  parseError,
  formatDate,
  getValuesByKey,
  getEndOfFinancialYear,
  getFormattedDateIso,
  getStartTimeOfDay,
  getPdfToken
} from '../utils/helperFunctions';
import { changeDateRange, setReportDateFilterRange } from './reports';
import { fetchAccountsCurrentBalance, handleStatementPaginate } from './contacts';
import { getUserGuidesStatus } from './user';
import { isArray, map } from 'lodash';
import { unSubscribeEvents } from '../socket';
import { updateAllVoucherSettings } from './settings';
import { getUserProfile } from './user';
import { setItemFilterSetting } from './items';

const CONST_ERROR_MESSAGE = 'There was a error, please try again';

export const similarCompanies = payload => {
  return {
    type: SIMILAR_COMPANIES_DATA,
    payload: payload
  };
};

export const changeFinancialYear = (payload, callback) => dispatch => {
  const currentFinancialEndDate = getEndOfFinancialYear(new Date());
  if (
    moment(payload.startDate).year() > moment(currentFinancialEndDate).year() + 1 ||
    moment(payload.endDate).year() > moment(currentFinancialEndDate).year() + 1
  ) {
    dispatch(openSnackbar('Invalid Year selected', ERROR));
    return;
  }
  sessionStorage.setItem('startDate', JSON.stringify(payload.startDate));
  sessionStorage.setItem('endDate', JSON.stringify(payload.endDate));
  dispatch({
    type: CHANGE_FINANCIAL_YEAR,
    payload: payload
  });
  dispatch({
    type: VOUCHER_DATE_RANGE,
    date: {
      startDate: formatDate(payload.startDate),
      endDate: formatDate(payload.endDate)
    }
  });
  dispatch({
    type: SET_FILTER_DATES,
    payload: {
      startDate: formatDate(payload.startDate),
      endDate: formatDate(payload.endDate)
    }
  });
  dispatch(
    changeDateRange({
      startDate: formatDate(payload.startDate),
      endDate: formatDate(payload.endDate)
    })
  );
  dispatch(setReportDateFilterRange(formatDate(payload.startDate), formatDate(payload.endDate)));
  callback && callback();
};

export const updateUserCompanySettings = (obj, callback) => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    }
  } = getState();
  const payload = {
    financialYear: {
      startDate: obj.startDate,
      endDate: obj.endDate
    }
  };
  api.updateUserCompanySettings(currentCompany.id, id, userSettingId, payload).then(() => {
    callback && callback();
  });
};

export const updateUserVoucherPartySettings = () => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    },
    contacts: { quickPartyCreation }
  } = getState();
  const payload = {
    quickPartyCreation
  };

  return api
    .updateUserCompanySettings(currentCompany.id, id, userSettingId, payload)
    .catch(error => {
      serverError(error);
    });
};

export const updateUserItemFilterSettings = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    }
  } = getState();
  api.updateUserItemFilterSettings(currentCompany.id, id, userSettingId, payload).then(() => {
    callback && callback();
  });
};

export const updateUserCompanyBulkItemSettings = callback => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    },
    items: { bulkItemSetting }
  } = getState();

  api
    .updateUserCompanyBulkItemSettings(currentCompany.id, id, userSettingId, bulkItemSetting)
    .then(() => {
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

export const handleDeleteAccount = callback => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: { refAccountId }
  } = getState();
  api
    .deleteAccount(companyId, refAccountId)
    .then(response => {
      dispatch(openSnackbar('Account Deleted Successfully'));
      dispatch(handleAccountSearch());
      callback && callback(companyId);
    })
    .catch(err => {
      dispatch(openSnackbar(err.response.data.error.message, ERROR));
      console.log(err.response);
    });
};

export const getUserCompanySettings = () => (dispatch, getState) => {
  const {
    currentCompany,
    user: { id }
  } = getState();
  api.fetchUserCompanySettings(currentCompany.id, id).then(res => {
    dispatch({
      type: UPDATE_USER_COMPANY_SETTING,
      payload: res
    });
    let voucherSettings = res.voucherSettings || {};
    if (
      voucherSettings &&
      voucherSettings[Object.keys(voucherSettings)[0]] &&
      voucherSettings[Object.keys(voucherSettings)[0]].columnVisibility
    ) {
      dispatch({
        type: UPDATE_USER_COMPANY_VOUCHER_SETTING,
        voucherSettings: res.voucherSettings || {}
      });
    } else {
      const newVoucherSettings = generateDefaultVoucherSettings(voucherSettings);
      dispatch({
        type: UPDATE_USER_COMPANY_VOUCHER_SETTING,
        voucherSettings
      });
      dispatch({ type: UPDATE_USER_COMPANY_SETTING, payload: { voucherSettings } });
      dispatch(updateAllVoucherSettings(voucherSettings));
    }
    dispatch(changeFinancialYear(res.financialYear));
    if (res.itemSettings && res.itemSettings.length) {
      let settings = res.itemSettings.reduce((acc, item) => {
        acc[item.key] = {
          ...item,
          id: item['key']
        };
        delete acc[item.key]['key'];
        return acc;
      }, {});

      dispatch(setItemFilterSetting(settings));
    }

    if (res.bulkItemSetting) {
      dispatch({ type: SET_ITEM_BULK_SETTING, data: res.bulkItemSetting });
    }

    dispatch({ type: HANDLE_PARTY_CREATION_MODE, flag: res.quickPartyCreation });
  });
};

export const getCompanySettings = () => (dispatch, getState) => {
  const { currentCompany } = getState();
  api.fetchCompanySettings(currentCompany.id).then(res => {
    dispatch({ type: FETCH_COMPANY_SETTINGS_SUCCESS, payload: res, iCompanyId: currentCompany.id });
    dispatch({ type: SET_COMPANY_VOUCHER_SETTINGS, payload: res.companyVoucherSettings });
  });
};

export const getCompanyVoucherTemplates = id => (dispatch, getState) => {
  dispatch({
    type: PDF_TEMPLATES_REQUESTED
  });
  api.fetchTemplates(id).then(res => {
    dispatch({
      type: FETCH_COMPANY_TEMPLATE_LIST,
      payload: res,
      companyId: id
    });
    dispatch({
      type: PDF_TEMPLATES_SUCCESS
    });
  });
};

export const toggleSimilarCompanyCheck = toggleState => {
  return {
    type: SIMILAR_COMPANIES_CHECK,
    payload: toggleState
  };
};

export const registerCompanyPayload = payload => {
  return {
    type: REGISTER_COMPANY_PAYLOAD,
    payload: payload
  };
};

export const storeTeamMembers = payload => {
  return {
    type: MEMBERS_LIST,
    payload: payload
  };
};

export const fetchSkubarCodeTemplates = callback => (dispatch, getState) => {
  const { currentCompany } = getState();
  api
    .fetchSkubarCodeTemplates(currentCompany.id)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => console.log(error));
};

export const fetchSkubarCodeTemplatesV3 = callback => (dispatch, getState) => {
  const { currentCompany } = getState();
  api
    .fetchSkuV3Templates(currentCompany.id)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => console.log(error));
};

export const fetchPartyTemplates = callback => (dispatch, getState) => {
  const { currentCompany } = getState();
  api
    .fetchPartyTemplates(currentCompany.id)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => console.log(error));
};

export const uploadTallyData = (payload, companyId, callback) => (dispatch, getState) => {
  payload.id = companyId;
  api
    .uploadTallyData(companyId, payload)
    .then(response => {
      dispatch(openSnackbar('Tally Data Uploaded Successfully'));
      getStatus(dispatch, response.id, 'tallyPartyImport');
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
      dispatch(openSnackbar('File rejected', ERROR));
    });
};

export const getProgressStatus = (trackerId, key = 'items', callback) => (dispatch, getState) => {
  if (!trackerId) {
    trackerId = localStorage.getItem(`${key}_trackerId`);
  }
  const { currentCompany } = getState();
  if (!trackerId) return;
  api
    .fetchProgressTrackerStatus(currentCompany.id, trackerId)
    .then(response => {
      if (response.isCompleted) {
        if (response.result && response.result.info) {
          window.open(response.result.info.Location, '_blank').focus();
          localStorage.removeItem(`${key}_trackerId`);
        } else {
          dispatch(openSnackbar('Action Successfull'));
        }
      }
      dispatch({
        type: SET_PROGRESS_STATUS_BY_ID,
        payload: {
          [key]: response
        }
      });
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
    });
};

export const getStatus = (dispatch, trackerId, key, callback) => {
  dispatch(
    getProgressStatus(trackerId, key, response => {
      if (!response.isCompleted) {
        setTimeout(() => {
          getStatus(dispatch, trackerId, key, callback);
        }, 2000);
      } else {
        callback && callback(response);
      }
    })
  );
};

export const getProgressTracker = callback => (dispatch, getState) => {
  const { currentCompany } = getState();
  dispatch({
    type: FETCH_PROGRESS_TRACKER_LIST
  });
  api
    .fetchProgressTracker(currentCompany.id)
    .then(response => {
      dispatch({
        type: FETCH_PROGRESS_TRACKER_LIST_SUCCESS,
        payload: response
      });
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: FETCH_PROGRESS_TRACKER_LIST_FAILED
      });
    });
};

export const postAcknowledgeStatus = (trackerId, callback) => (dispatch, getState) => {
  const { currentCompany } = getState();
  dispatch({
    type: FETCH_PROGRESS_TRACKER_LIST
  });
  return api
    .fetchAcknowledgeStatus(currentCompany.id, trackerId)
    .then(response => {
      dispatch(getProgressTracker());
      dispatch(openSnackbar('Acknowledged successfully'));
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
    });
};

export const resolveError = (trackerId, itemId, callback) => (dispatch, getState) => {
  const { currentCompany } = getState();
  dispatch({
    type: FETCH_PROGRESS_TRACKER_LIST
  });
  return api
    .resolveTask(currentCompany.id, trackerId, itemId)
    .then(response => {
      dispatch(getProgressTracker());
      dispatch(openSnackbar('Resolved successfully'));
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
    });
};

export const addCompanyPdfTemplates = (payload, companyId, callback) => (dispatch, getState) => {
  payload.iCompanyId = companyId;
  api
    .createCompanyPdfTemplates(companyId, payload)
    .then(response => {
      dispatch(openSnackbar('Default Template Changed'));
      callback && callback(response);
    })
    .catch(error => console.log(error));
};

export const fetchCachedAPIList = () => (dispatch, getState) => {
  api
    .getCachedAPIList()
    .then(response => {
      dispatch({
        type: SET_CACHE_API_LIST,
        payload: response
      });
    })
    .catch(error => console.log(error));
};

export const editCompanyPdfTemplates = (id, payload, companyId, callback) => (
  dispatch,
  getState
) => {
  payload.iCompanyId = companyId;
  api
    .editCompanyPdfTemplates(companyId, payload, id)
    .then(response => {
      dispatch(openSnackbar('Template Edited successfully'));
      callback && callback(response);
    })
    .catch(error => console.log(error));
};

export const fetchCompanies = () => (dispatch, getState) => {
  dispatch({ type: COMPANIES_LIST_REQUESTED, payload: {} });

  const { user } = getState();

  api
    .fetchCompanies(user.id)
    .then(response => {
      dispatch({ type: COMPANIES_LIST_SUCCESS, payload: sortBy(response, ['name']) });
    })
    .catch(error => dispatch({ type: COMPANIES_LIST_FAILED, payload: error }));
};

export const selectCompany = companyId => (dispatch, getState) => {
  const { companies } = getState();
  // avoid redirect to purchase list when
  // dashboard tab url is other than voucher
  // TODO: have a centralized url navigation actions
  // if (!currentCompany || currentCompany.id !== companyId) {
  const selectedCompany = companies.find(company => company.id === companyId);
  selectedCompany && mixpanel.people.union('companies', selectedCompany.name);
  if (!selectedCompany) {
    //added for testing remove later
    console.log('companyNotFound', companies, companyId);
  }
  handleChangeCompany(selectedCompany, dispatch, getState);
  // }
};

export const addToCompaniesList = data => dispatch => {
  dispatch({
    type: ADD_COMPANIES_LIST,
    payload: {
      ...data.createdCompany,
      country: data.createdBranch ? data.createdBranch.country : INDIA
    }
  });
  dispatchCompanyAccessToken(data, data.createdCompany.id, dispatch);
};

export const fetchCompanyBranch = (companyId, callback) => (dispatch, getState) => {
  const { currentCompany } = getState();

  dispatch({ type: COMPANY_BRANCH_REQUESTED });
  api
    .fetchCompanyBranch(companyId || currentCompany.id)
    .then(response => {
      let filteredCompay = response;
      if (response.length === 0) {
        dispatch(openSnackbar('You have access to no branches, please contact Invock', ERROR));
      }
      dispatch({ type: FETCH_ALL_BRANCHES_SUCCESS, response });
      dispatch({ type: COMPANY_BRANCH_SUCCESS, payload: filteredCompay });
      dispatch({ type: COMPANY_BRANCH_UPDATE, payload: filteredCompay });
      if (callback) dispatch(callback);
    })
    .catch(error => dispatch({ type: COMPANY_BRANCH_FAILURE, payload: error }));
};

export const authCompany = payload => (dispatch, getState) => {
  dispatch({ type: COMPANY_AUTH_REQUESTED, payload: {} });
  const {
    currentCompany,
    user: { id, name, phone },
    guides: { guidesList }
  } = getState();
  mixpanel.identify(phone);
  mixpanel.people.set({
    phone,
    name
  });
  mixpanel.track('login-company', { name });

  api
    .companyLogin(id, currentCompany.id, payload)
    .then(data => {
      dispatchCompanyAccessToken(data, currentCompany.id, dispatch);
      dispatch(getUserCompanySettings());
      dispatch(getCompanySettings());

      //If no guide data avaliable the fetch it
      (!guidesList || Object.keys(guidesList).length === 0) && dispatch(getUserGuidesStatus());
      // dispatch({ type: COMPANY_AUTH_SUCCESS, payload: data });
      navigate(dispatch, `/${data.accessToken.iCompanyId}/home/items/item-groups`);
    })
    .catch(error => {
      console.log(error);
      const {
        response: {
          data: {
            error: { message }
          }
        }
      } = error;
      dispatch({ type: COMPANY_AUTH_FAILED, payload: error });
      dispatch(openSnackbar(message, ERROR));
    });
};

export const updateCompanyPassword = (userId, companyId, password) => dispatch => {
  dispatch({ type: PASSWORD_RESET_REQUESTED, payload: {} });

  api
    .updateCompanyPassword(userId, companyId, { password: password })
    .then(result => {
      dispatch({ type: PASSWORD_RESET_SUCCESS, payload: result });
      dispatch(openSnackbar('Password changed'));
      dispatchCompanyAccessToken(result, companyId, dispatch);
      navigate(dispatch, `/${result.accessToken.iCompanyId}/home/vouchers/purchase/list`);
    })
    .catch(e => {
      let errMsg = '';
      console.log(e.response);
      if (e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
        errMsg = parseError(e.response.data.error.message);
      }
      dispatch(openSnackbar(errMsg || CONST_ERROR_MESSAGE, ERROR));
      dispatch({ type: PASSWORD_RESET_FAILED, payload: e });
    });
};

export const fetchCompanyMembers = companyId => dispatch => {
  dispatch({ type: COMPANY_MEMBERS_REQUESTED, payload: {} });

  api
    .fetchCompanyMembers(companyId)
    .then(response => {
      dispatch({ type: COMPANY_MEMBERS_SUCCESS, payload: response });
      dispatch({ type: MEMBERS_LIST, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_MEMBERS_FAILED, payload: error }));
};

const dispatchCompanyAccessToken = (tokenObject, companyId, dispatch) => {
  setCompanySessionToken(tokenObject.accessToken.id, companyId);
  const token = getPdfToken(tokenObject);
  setCompanyPdfToken(token, companyId);
  dispatch({ type: COMPANY_AUTH_SUCCESS, payload: tokenObject });
};

export const companySignout = () => (dispatch, getState) => {
  const {
    currentCompany: { id, name }
  } = getState();
  mixpanel.track('logout-company', { name });
  const companyToken = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${id}`);
  dispatch(deleteUserSession(id, companyToken, () => dispatch(handleCompanySignout(id))));
};

export const handleCompanySignout = id => (dispatch, getState) => {
  unSubscribeEvents();
  // socket.removeAllListeners()
  dispatch({
    type: PROGRESS_TRACKER,
    payload: {
      percentage: 0,
      isCompleted: true
    }
  });
  localStorage.removeItem(`${CURRENT_COMPANY_TOKEN}_${id}`);
  // setCompanySessionToken(null, id);
  sessionStorage.clear();
  dispatch({ type: RESET_ALL_COLUMN_VISIBILITY });
  navigate(dispatch, '/companies/list');
  dispatch({ type: COMPANY_SIGNOUT, payload: id });
};

const handleChangeCompany = (company, dispatch, getState) => {
  const {
    router: {
      location: { pathname }
    }
  } = getState();
  if (!company) {
    navigate(dispatch, '/');
    return;
  }
  dispatch({ type: CHANGE_COMPANY, payload: company });
  if (!company.accessToken || company.accessToken === null) {
    navigate(dispatch, `/companies/company/${company.id}`);
    return;
  }
  setCompanySessionToken(company.accessToken, company.id);
  dispatch(getUserCompanySettings());
  dispatch(getCompanySettings());
  // ALways navigate inside company and if authorization fail
  // then route to company login screen
  const page = 'items/item-groups';
  // const type = VOUCHER_TYPE_PURCHASE;
  //console.log('pathname', pathname);
  if (pathname === '/companies/list' || pathname === '/companies/add') {
    //console.log('list', pathname);
    navigate(dispatch, `/${company.id}/home/${page}`);
  }
};

export const addCompanyBranch = (branch, modalKey, callback) => (dispatch, getState) => {
  dispatch({ type: COMPANY_ADD_BRANCH_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  mixpanel.track('create-branch', { name: branch.name });
  api
    .addCompanyBranch(id, branch)
    .then(response => {
      dispatch({ type: COMPANY_ADD_BRANCH_SUCCESS, payload: response });
      dispatch(fetchCompanyBranch());
      dispatch(fetchTeamRoles(id));
      callback && callback();
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => dispatch({ type: COMPANY_ADD_BRANCH_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const updateCompanyBranch = (branch, modalKey, callback) => (dispatch, getState) => {
  dispatch({ type: COMPANY_UPDATE_BRANCH_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .editCompanyBranch(id, branch.id, branch)
    .then(response => {
      dispatch({ type: COMPANY_UPDATE_BRANCH_SUCCESS, payload: response });
      dispatch(fetchCompanyBranch());
      callback && callback();
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => dispatch({ type: COMPANY_UPDATE_BRANCH_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const addCompanyBankAccount = (bankAcc, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_ADD_BANK_ACCOUNT_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  mixpanel.track('create-bank-account', { name: bankAcc.name });
  api
    .addCompanyBankAccount(id, bankAcc)
    .then(response => {
      dispatch({ type: COMPANY_ADD_BANK_ACCOUNT_SUCCESS, payload: response });
      // dispatch(fetchCompanies());
      // dispatch(selectCompany(id));
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => {
      dispatch({ type: COMPANY_ADD_BANK_ACCOUNT_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const updateCompanyBankAccount = (bankAcc, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_UPDATE_BANK_ACCOUNT_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .editCompanyBankAccount(id, bankAcc.id, bankAcc)
    .then(response => {
      dispatch({ type: COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS, payload: response });
      // dispatch(fetchCompanies());
      // dispatch(selectCompany(id));
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() =>
      dispatch({ type: COMPANY_UPDATE_BANK_ACCOUNT_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const fetchCompanyBankAccounts = companyId => (dispatch, getState) => {
  dispatch({ type: COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchCompanyBankAccounts(companyId || id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS, payload: response });
    })
    .catch(() =>
      dispatch({ type: COMPANY_FETCH_BANK_ACCOUNTS_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const changeCompanyPassword = (companyId, payload, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_PASSWORD_CHANGE_REQUESTED, payload: {} });

  api
    .changeCompanyPassword(companyId, payload)
    .then(response => {
      dispatch({ type: COMPANY_PASSWORD_CHANGE_SUCCESS, payload: response });
      dispatchCompanyAccessToken(response, companyId, dispatch);
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(error => {
      const {
        response: {
          data: {
            error: { message }
          }
        }
      } = error;
      dispatch({ type: COMPANY_PASSWORD_CHANGE_FAILED, payload: message });
    });
};

export const fetchRoleConfig = companyId => (dispatch, getState) => {
  dispatch({ type: COMPANY_ROLE_CONFIG_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchRoleConfig(companyId || id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_ROLE_CONFIG_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_FETCH_ROLE_CONFIG_FAILED, payload: error }));
};

export const addMemberRole = (companyId, branchId, payload, errorCallback) => (
  dispatch,
  getState
) => {
  dispatch({ type: COMPANY_ADD_MEMBER_ROLE_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .addMemberRole(companyId || id, branchId, payload)
    .then(response => {
      dispatch({ type: COMPANY_ADD_MEMBER_ROLE_SUCCESS, payload: response });
      dispatch(fetchTeamRoles(companyId));
      // dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => {
      errorCallback();
      dispatch({ type: COMPANY_ADD_MEMBER_ROLE_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const removeMemberRole = (companyId, branchId, payload, errorCallback) => (
  dispatch,
  getState
) => {
  dispatch({ type: COMPANY_REMOVE_MEMBER_ROLE_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .removeMemberRole(companyId || id, branchId, payload)
    .then(response => {
      dispatch({ type: COMPANY_REMOVE_MEMBER_ROLE_SUCCESS, payload: response });
      dispatch(fetchTeam(companyId));
    })
    .catch(() => {
      errorCallback();
      dispatch({ type: COMPANY_REMOVE_MEMBER_ROLE_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const resetCompanyRoles = (companyId, branchId, payload) => (dispatch, getState) => {
  dispatch({ type: COMPANY_RESET_ROLES_REQUESTED });
  const {
    currentCompany: { id }
  } = getState();
  api
    .companyRolesRemoveMulti(companyId || id, branchId, payload)
    .then(() => {
      dispatch({ type: COMPANY_RESET_ROLES_SUCCESS });
      dispatch(fetchTeamRoles(companyId));
      // dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => {
      dispatch({ type: COMPANY_RESET_ROLES_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const fetchTeamRoles = companyId => (dispatch, getState) => {
  dispatch({ type: COMPANY_FETCH_TEAM_ROLE_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchTeamRoles(companyId || id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_TEAM_ROLE_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_FETCH_TEAM_ROLE_FAILED, payload: error }));
};

export const addTeamMember = (companyId, newMember) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  mixpanel.track('invite-team-member', { name: newMember.name, phone: newMember.phone });

  return api.addTeamMember(companyId || id, newMember);
};

export const updateCompanyDetails = (companyId, details, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_UPDATE_DETAILS_REQUESTED, payload: {} });
  const data = {
    ...details,
    financialYearStart: details.financialYearStart
      ? getFormattedDateIso(getStartTimeOfDay(details.financialYearStart))
      : undefined,
    bookStartDate: details.bookStartDate
      ? getFormattedDateIso(getStartTimeOfDay(details.bookStartDate))
      : undefined
  };
  api
    .updateCompanyDetails(companyId, data)
    .then(() => {
      dispatch({ type: COMPANY_UPDATE_DETAILS_SUCCESS, payload: details, iCompanyId: companyId });
      dispatch(toggleModalView(modalKey, false));
      dispatch(getCompanySettings());
    })
    .catch(error => {
      if (isArray(error.response.data.error.message)) {
        let errorMessage = `Can't update company settings.`;
        error.response.data.error.message.forEach(item => {
          errorMessage += `
          item name: ${item.name} Barcode: ${item.numericSkuBarcode} 
          `;
        });
        dispatch(openSnackbar(errorMessage, ERROR));
      } else serverError(error);
      dispatch({ type: COMPANY_UPDATE_DETAILS_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const updateBranchSettings = (companyId, branchId, settingArray) => (dispatch, getState) => {
  const payload = { voucherNoSuffix: settingArray };
  api
    .updateBranchSettings(companyId, branchId, payload)
    .then(() => {
      dispatch(toggleBranchSettingModal(false));
    })
    .catch(err => serverError(err));
};

export const deleteCompanyBranch = (branchId, modalKey, callback) => (dispatch, getState) => {
  dispatch({ type: COMPANY_DELETE_BRANCH_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .deleteCompanyBranch(id, branchId)
    .then(response => {
      dispatch({ type: COMPANY_DELETE_BRANCH_SUCCESS, payload: response });
      dispatch(fetchCompanyBranch());
      callback && callback();
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() => dispatch({ type: COMPANY_DELETE_BRANCH_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const deleteCompanyBankAccount = (bankAccId, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_DELETE_BANK_ACCOUNT_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .deleteCompanyBankAccount(id, bankAccId)
    .then(response => {
      dispatch({
        type: COMPANY_DELETE_BANK_ACCOUNT_SUCCESS,
        payload: response,
        bankAccId: bankAccId
      });
      // dispatch(fetchCompanyBranch());
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() =>
      dispatch({ type: COMPANY_DELETE_BANK_ACCOUNT_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const fetchTeam = companyId => (dispatch, getState) => {
  dispatch({ type: COMPANY_FETCH_TEAM_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchTeam(companyId || id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_TEAM_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_FETCH_TEAM_FAILED, payload: error }));
};

export const fetchAccountGroups = () => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchAccountGroups(id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_FETCH_ACCOUNT_GROUP_FAILED, payload: error }));
};

export const getCompanyAccountById = (id, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  api
    .fetchCompanyAccountById(iCompanyId, id)
    .then(res => {
      callback && callback(res);
    })
    .catch(error => dispatch(openSnackbar('Action Error / Incorrect Id', 'error')));
};

export const addCompanyAccount = (payload, modalKey) => (dispatch, getState) => {
  dispatch({ type: COMPANY_ADD_ACCOUNT_REQUESTED, payload: {} });
  dispatch({ type: ACCOUNT_FORM_REQUESTED });
  const {
    currentCompany: { id },
    vouchers: {
      addAccountField: { key }
    },
    router
  } = getState();
  mixpanel.track('save-account-click', { name: payload.name });
  api
    .addCompanyAccount(id, payload)
    .then(response => {
      dispatch({ type: ACCOUNT_FORM_SUCCESS });
      dispatch({ type: COMPANY_ADD_ACCOUNT_SUCCESS, payload: response, iCompanyId: id });
      if (router.location.pathname.includes('/home/accounts')) {
        navigate(dispatch, `/${id}/home/accounts/${response.id}/list`);
      }
      dispatch(fetchAccountGroups());
      // dispatch(fetchAllAccounts(id));
      dispatch(fetchExpenseIncomeAccounts());
      dispatch(toggleModalView(modalKey, false));
      dispatch(openSnackbar('Account Created'));
      dispatch(handleAccountSearch());
      key && dispatch(updateAccountInField(response));
    })
    .catch(e => {
      const {
        response: {
          data: {
            error: { message }
          }
        }
      } = e;
      dispatch({ type: ACCOUNT_FORM_FAILED });
      dispatch(openSnackbar(message, ERROR));
    });
};

export const updateCompanyAccount = (id, payload, modalKey, callback) => (dispatch, getState) => {
  dispatch({ type: ACCOUNT_FORM_REQUESTED });
  const generatePayload = {
    name: payload.name,
    isDebit: payload.isDebit,
    aliasName: payload.aliasName,
    balance: payload.balance,
    balanceDate: payload.balanceDate,
    defaultValue: payload.defaultValue,
    defaultValueUnit: payload.defaultValueUnit,
    defaultValueMethod: payload.defaultValueMethod
  };
  const {
    currentCompany: { id: iCompanyId },
    vouchers: {
      addAccountField: { key }
    },
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  api
    .updateCompanyAccount(iCompanyId, generatePayload, id)
    .then(response => {
      dispatch({ type: ACCOUNT_FORM_SUCCESS });
      dispatch(fetchAccountGroups());
      // dispatch(fetchAllAccounts(iCompanyId));
      dispatch(fetchExpenseIncomeAccounts());
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchAccountsCurrentBalance(id));
      dispatch(handleStatementPaginate(iCompanyId, id, startDate, endDate, 'partyStatement'));
      dispatch(handleFetchAccountCard(iCompanyId, id));
      dispatch(handleAccountSearch());
      dispatch(openSnackbar('Account Updated'));
      key && dispatch(updateAccountInField(response));
      callback && callback();
    })
    .catch(error => {
      const {
        response: {
          data: {
            error: { message }
          }
        }
      } = error;
      dispatch({ type: ACCOUNT_FORM_FAILED });
      dispatch({ type: COMPANY_AUTH_FAILED, payload: error });
      dispatch(openSnackbar(message, ERROR));
      // dispatch({ type: COMPANY_ADD_ACCOUNT_FAILED, payload: CONST_ERROR_MESSAGE });
    });
};

export const addAccountFieldData = (data = {}) => dispatch => {
  dispatch({ type: COMPANY_ADD_ACCOUNT_FIELD_METADATA, data });
};

export const updateAccountInField = account => (dispatch, getState) => {
  const {
    vouchers: {
      addAccountField: { key, index }
    }
  } = getState();
  if (key === OTHER_CHARGES) {
    dispatch(updateAccount(account, index));
  } else if (key === OTHER_CHARGES_AFTER_TAX) {
    dispatch(updateAccountField(account, index));
  } else if (key === EXPENSE) {
    dispatch({ type: ADD_EXPENSE_LINE_ITEM, account, index });
    // dispatch(fetchAllAccounts());
  } else if (key === 'JOURNAL') {
    dispatch({ type: 'ADD_JOURNAL_ACCOUNT', account, index });
  } else if (key === RP_VOUCHER) {
    dispatch({ type: ADD_RP_ACCOUNT, account, index });
  }
  dispatch({ type: COMPANY_ADD_ACCOUNT_FIELD_METADATA, data: {} });
};

// export const fetchAllAccounts = () => (dispatch, getState) => {
//   const {
//     currentCompany: { id }
//   } = getState();

//   api
//     .fetchAllAccounts(id)
//     .then(response => dispatch({ type: COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS, payload: response }))
//     .catch(error => dispatch({ type: COMPANY_FETCH_ALL_ACCOUNTS_FAILED, payload: error }));
// };

export const handleAccountSearch = (
  accountGroupPath = '',
  pageNo = 1,
  searchTextParam = '',
  searchBy = ''
) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    partyAccountListFilters: {
      [PARTY_LIST_DATA]: { selectedFilterType, selectedSearchBy, searchText }
    }
  } = getState();
  dispatch({ type: ACCOUNT_SEARCH_REQUESTED, page: pageNo });
  let query = '';
  if (selectedFilterType) {
    query += `accountGroupPath=${accountGroupPath || selectedFilterType}&`;
  }
  if (searchTextParam || searchText) {
    query += `searchText=${searchTextParam || searchText}&`;
  }
  if (selectedSearchBy) {
    query += `searchBy=${searchBy || selectedSearchBy}`;
  }

  api
    .accountSearch(iCompanyId, pageNo, query)
    .then(response => {
      dispatch({ type: ACCOUNT_SEARCH_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: ACCOUNT_SEARCH_FAILED, payload: error }));
};

export const fetchExpenseIncomeAccounts = () => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  // api
  //   .fetchIndirectExpensesAccounts(id)
  //   .then(response => {
  //     dispatch({ type: COMPANY_FETCH_INDIRECT_EXPENSE_ACCOUNTS_SUCCESS, payload: response });
  //   })
  //   .catch(error =>
  //     dispatch({ type: COMPANY_FETCH_INDIRECT_EXPENSE_ACCOUNTS_FAILED, payload: error })
  //   );

  Promise.all([
    api.fetchIndirectExpenseAccounts(id),
    api.fetchIndirectIncomeAccounts(id),
    api.fetchDirectIncomeAccounts(id),
    api.fetchDutiesAndTaxesAccounts(id)
  ])
    .then(([indirectExpense, indirectIncome, directIncome, dutiesAndTaxes]) => {
      let response = [];
      if (indirectExpense != null) {
        response.push(...indirectExpense, ...indirectIncome, ...directIncome, ...dutiesAndTaxes);
      }
      dispatch({ type: COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS, payload: response });
    })
    .catch(error =>
      dispatch({ type: COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_FAILED, payload: error })
    );
};

export const fetchStates = () => (dispatch, getState) => {
  dispatch({ type: FETCH_STATES_REQUESTED });
  api
    .getStatesMaster()
    .then(response => {
      dispatch({ type: FETCH_STATES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_STATES_FAILED, payload: error }));
};

export const fetchCountries = () => (dispatch, getState) => {
  dispatch({ type: FETCH_COUNTRIES_REQUESTED });
  api
    .getCountriesMaster()
    .then(response => {
      dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_COUNTRIES_FAILED, payload: error }));
};

export const deleteCompanyTeamMember = (companyId, memberId, otpToken, signoutOnSuccess) => (
  dispatch,
  getState
) => {
  dispatch({ type: COMPANY_DELETE_TEAM_MEMBER_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  query = otpToken ? `?otpToken=${otpToken}` : '';
  api
    .deleteCompanyTeamMember(companyId || id, memberId, query, { otpToken })
    .then(response => {
      dispatch({ type: COMPANY_DELETE_TEAM_MEMBER_SUCCESS, payload: response });
      if (signoutOnSuccess) {
        dispatch(companySignout());
      } else {
        dispatch(fetchTeam(companyId));
        dispatch(fetchTeamRoles(companyId));
      }
    })
    .catch(err => {
      dispatch({ type: COMPANY_DELETE_TEAM_MEMBER_FAILED, payload: CONST_ERROR_MESSAGE });
      serverError(err);
    });
};

export const fetchIndustryTypes = () => (dispatch, getState) => {
  dispatch({ type: FETCH_INDUSTRY_TYPES_REQUESTED });
  api
    .getIndustryTypesMaster()
    .then(response => {
      dispatch({ type: FETCH_INDUSTRY_TYPES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_INDUSTRY_TYPES_FAILED, payload: error }));
};

export const fetchCities = (pageNo = 1, searchText = '', selectedStates = []) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_CITIES_REQUESTED });

  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (searchText) {
    query += `searchText=${searchText}`;
  }
  if (selectedStates && selectedStates.length > 0) {
    query += `&state=${getValuesByKey(selectedStates).toString()}`;
  }
  console.log(selectedStates);

  api
    .getCities(id, pageNo, query)
    .then(response => {
      dispatch({ type: FETCH_CITIES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_CITIES_FAILED, payload: error }));
};

export const fetchPartiesStates = (pageNo = 1, searchText = '') => (dispatch, getState) => {
  dispatch({ type: FETCH_PARTIES_STATES_REQUESTED });

  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (searchText) {
    query += `searchText=${searchText}`;
  }
  api
    .getPartiesStates(id, query)
    .then(response => {
      dispatch({ type: FETCH_PARTIES_STATES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_PARTIES_STATES_FAILED, payload: error }));
};

export const fetchPartyAccountTypes = () => (dispatch, getState) => {
  dispatch({ type: FETCH_PARTY_ACCOUNT_TYPES_REQUESTED });
  api
    .getPartyAccountTypes()
    .then(response => {
      dispatch({ type: FETCH_PARTY_ACCOUNT_TYPES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_PARTY_ACCOUNT_TYPES_FAILED, payload: error }));
};

export const fetchActiveUserSessions = companyId => (dispatch, getState) => {
  dispatch({ type: COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .getActiveSessions(companyId || id)
    .then(response => {
      dispatch({ type: COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS, payload: response });
    })
    .catch(() =>
      dispatch({ type: COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const deleteUserSession = (companyId, token, callback) => (dispatch, getState) => {
  dispatch({ type: COMPANY_DELETE_USER_SESSION_REQUESTED, payload: {} });
  const companyToken = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`);
  api
    .deleteAccessToken(companyId, token)
    .then(response => {
      dispatch({ type: COMPANY_DELETE_USER_SESSION_SUCCESS, payload: response });
      token === companyToken && dispatch(handleCompanySignout(companyId)); //if current session token deleted logout from company
      callback && callback();
    })
    .catch(() => {
      dispatch({ type: COMPANY_DELETE_USER_SESSION_FAILED, payload: CONST_ERROR_MESSAGE });
      callback && callback();
    });
};

export const deleteAllUserSessions = (companyId, sessions) => dispatch => {
  dispatch({ type: COMPANY_DELETE_USER_SESSION_REQUESTED, payload: {} });
  const companyToken = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`);
  Promise.all(map(sessions, session => api.deleteAccessToken(companyId, session.accessToken)))
    .then(response => {
      dispatch({ type: COMPANY_DELETE_USER_SESSION_SUCCESS, payload: response });
      if (sessions.find(s => s.accessToken === companyToken)) {
        //if current session token deleted logout from company
        dispatch(handleCompanySignout(companyId));
      } else {
        dispatch(fetchActiveUserSessions(companyId));
      }
    })
    .catch(() =>
      dispatch({ type: COMPANY_DELETE_USER_SESSION_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const setEShopStatus = status => (dispatch, getState) => {
  dispatch({ type: COMPANY_SET_E_SHOP_STATUS_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  mixpanel.track('i-shop-enable-disable', { enable: status });

  api
    .toggleEShopStatus(id, status ? 'enable' : 'disable')
    .then(response => {
      dispatch({ type: COMPANY_SET_E_SHOP_STATUS_SUCCESS, status });
    })
    .catch(() =>
      dispatch({ type: COMPANY_SET_E_SHOP_STATUS_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const getDirectContacList = (companyId, branchId, purposeId = 'all', callback) => (
  dispatch,
  getState
) => {
  branchId &&
    api
      .fetchDirectContact(companyId, branchId, purposeId, {})
      .then(response => {
        callback && callback(response);
      })
      .catch(err => {
        console.log(err);
        serverError(err);
      });
};

export const updateEshopName = name => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: COMPANY_UPDATE_E_SHOP_NAME_REQUESTED, payload: {} });
  api
    .updateEShop(id, { name })
    .then(response => {
      dispatch({ type: COMPANY_UPDATE_E_SHOP_NAME_SUCCESS, name });
    })
    .catch(error => {
      let errorMessage = '';
      errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.data &&
        error.response.data.error.data.message
          ? error.response.data.error.data.message
          : '';

      dispatch({
        type: COMPANY_UPDATE_E_SHOP_NAME_FAILED,
        errorMessage: errorMessage || CONST_ERROR_MESSAGE
      });
    });
};

export const getEShopShareMessage = () => (dispatch, getState) => {
  dispatch({ type: COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchEShopShareMessage(id)
    .then(response => {
      dispatch({ type: COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS, payload: response });
    })
    .catch(() =>
      dispatch({ type: COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED, payload: CONST_ERROR_MESSAGE })
    );
};

export const addMemberContact = (companyId, branchId, payload, callback) => (
  dispatch,
  getState
) => {
  api
    .addMemberContact(companyId, branchId, payload)
    .then(response => {
      dispatch(getDirectContacList(companyId, branchId, 'all', callback));
    })
    .catch(err => {
      serverError(err);
    });
};

export const setUserProfilePicture = data => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_PROFILE_PICTURE_REQUESTED });
  const {
    user: { id },
    currentCompany: { id: companyId }
  } = getState();
  api
    .setUserProfilePicture(companyId, id, data)
    .then(response => {
      dispatch({ type: UPDATE_USER_PROFILE_PICTURE_SUCCESS, payload: data });
      dispatch(getUserProfile());
    })
    .catch(error => dispatch({ type: UPDATE_USER_PROFILE_PICTURE_FAILED, payload: error }));
};

export const updateUserProfilePicture = (id, data) => (dispatch, getState) => {
  const {
    user: { id: userId }
  } = getState();
  dispatch({ type: UPDATE_USER_PROFILE_PICTURE_REQUESTED });

  api
    .updateUserProfile(userId, id, data)
    .then(response => {
      dispatch({ type: UPDATE_USER_PROFILE_PICTURE_SUCCESS, payload: response });
      dispatch(getUserProfile());
    })
    .catch(error => {
      dispatch({ type: UPDATE_USER_PROFILE_PICTURE_FAILED, payload: error });
    });
};

export const getItemVariant = (
  eshopId,
  chatId,
  attributeId,
  groupId,
  filters,
  callback,
  errorCallback
) => (dispatch, getState) => {
  let query = '';
  if (Object.keys(filters).length > 0) {
    Object.keys(filters).forEach((key, i) => {
      if (filters[key]) {
        query += query.includes('?')
          ? `&${key}=${filters[key].id.toString()}`
          : `?${key}=${filters[key].id.toString()}`;
      }
    });
  }

  let apiUrl = eshopId
    ? `/share-statuses/e-shop/${eshopId}/${groupId}/${attributeId}/variants${query}`
    : `/share-statuses/conversations/${chatId}/${groupId}/${attributeId}/variants${query}`;
  return api
    .fetchItemVariant(apiUrl)
    .then(response => {
      callback && callback(response);
    })
    .catch(err => {
      serverError(err);
      errorCallback && errorCallback();
    });
};

export const getVariantAttributeList = (eshopId, chatId, groupId, callback) => (
  dispatch,
  getState
) => {
  let apiUrl = eshopId
    ? `/share-statuses/e-shop/${eshopId}/${groupId}/item-attributes`
    : `/share-statuses/conversations/${chatId}/${groupId}/item-attributes`;
  return api
    .fetchAttributeList(apiUrl)
    .then(response => {
      callback && callback(response);
    })
    .catch(err => serverError(err));
};

export const fetchPOSBillingParty = () => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .fetchPOSBillingParty(id)
    .then(response => {
      dispatch({ type: POS_BILLING_PARTY, payload: response.data });
    })
    .catch(err => serverError(err));
};

export const getAttributeValues = (eshopId, attributeId, callback) => (dispatch, getState) => {
  return api
    .fetchAttributeValues(eshopId, attributeId)
    .then(response => {
      callback && callback(response);
    })
    .catch(err => serverError(err));
};

// user business card
export const generateUserBusinessCard = callback => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .generateUserBusinessCard(companyId)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};

export const fetchUserBusinessPublicShareStatus = storeKey => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  dispatch({ type: FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED, key: storeKey });
  api
    .getUserBusinessCard(companyId)
    .then(response => {
      const data = response.data[0]; //show last shared link
      dispatch({
        type: FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
        key: storeKey,
        payload: data
      });
    })
    .catch(error =>
      dispatch({
        type: FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
        key: storeKey,
        payload: error
      })
    );
};

export const toggleBranchSettingModal = (flag = false, branch = {}) => {
  return {
    type: TOGGLE_BRANCH_SETTING_MODAL,
    flag,
    branch
  };
};

function generateDefaultVoucherSettings(voucherSettings) {
  return reduce(
    VOUCHER_SETTINGS_LIST,
    (result, voucherType) => {
      voucherSettings[voucherType] = voucherSettings[voucherType]
        ? voucherSettings[voucherType]
        : {};
      if (!voucherSettings[voucherType] || !voucherSettings[voucherType].columnVisibility) {
        if (
          !(
            voucherType === VOUCHER_TYPE_CONTRA &&
            voucherType === VOUCHER_TYPE_JOURNAL &&
            voucherType === VOUCHER_TYPE_EXPENSE
          )
        ) {
          //if no col visibility fo voucher type then create
          voucherSettings[voucherType].columnVisibility = {};
          voucherSettings[voucherType].columnVisibility = reduce(
            voucherSettings[voucherType].hiddenColumns,
            (r, v) => {
              r[v] = false;
              return r;
            },
            { ...VOUCHER_DEFAULT_COLUMN_VISIBILITY }
          );
          voucherSettings[voucherType].columnVisibility.qtyPerBox = voucherSettings[voucherType]
            .columnVisibility.qtyPerBox
            ? voucherSettings[voucherType].columnVisibility.qtyPerBox
            : false;
        }
      }
      voucherSettings[voucherType].hidden = ![
        VOUCHER_TYPE_PURCHASE,
        VOUCHER_TYPE_SALES,
        VOUCHER_TYPE_RECEIPT,
        VOUCHER_TYPE_PAYMENT,
        VOUCHER_TYPE_SESTIMATION,
        VOUCHER_TYPE_STOCK_ADJUSTMENT
      ].includes(voucherType);
      return result;
    },
    {}
  );
}
