import { companyAuthFetch, extractData } from './server';
import { PARTIES } from '../constants';
// import * as CONSTANTS from '../constants';

export const fetchPartiesByState = companyId =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/parties-by-state-and-city`)
    .then(extractData);

export const getTrialBalance = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/trial-balance?${query}`)
    .then(extractData);

export const getAccountByPath = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/account-group/path?${query}`)
    .then(extractData);

export const getLedgerWiseReport = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/ledger-wise/page/${page}?${query}`)
    .then(extractData);

export const exportLedgerWiseReport = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/ledger-wise/page/${page}/export?${query}`)
    .then(extractData);

export const getYearlyWiseReport = (companyId, accountId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/accounts/${accountId}/year-Breakup?${query}`)
    .then(extractData);

// export const getMonthlyWiseReport = (companyId, accountId, startDate, endDate) =>
//   companyAuthFetch
//     .get(
//       `/i-companies/${companyId}/reports/accounts/${accountId}/date-wise?startTime=${startDate}&endTime=${endDate}`
//     )
//     .then(extractData);

export const getProfitAndLoss = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/reports/profit-loss?${query}`).then(extractData);

export const getBalanceSheet = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/balance-sheet?${query}`)
    .then(extractData);

export const fetchDayBook = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/day-book/page/${page}?${query}`)
    .then(extractData);

export const fetchDayBookExport = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/day-book/page/${page}/export?${query}`)
    .then(extractData);

export const fetchAccountGroups = (companyId, groupType, query, page) =>
  companyAuthFetch
    .get(
      //need to change after api is fixed
      groupType === PARTIES
        ? `/i-companies/${companyId}/reports/${groupType}/page/all?${query}`
        : `/i-companies/${companyId}/reports/${groupType}?${query}`
    )
    .then(extractData);

export const fetchPartiesPaymentDue = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/parties/payment-due/page/${page}?${query}`)
    .then(extractData);

export const exportPartiesPaymentDue = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/parties/payment-due/page/${page}/export?${query}`)
    .then(extractData);

export const fetchInventory = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/item-group-wise?${query}`)
    .then(extractData);

export const fetchAllItemsInventory = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/items/page/${page}?${query}`)
    .then(extractData);

export const fetchInventoryGroup = (companyId, itemGroupId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/reports/inventory/item-group/${itemGroupId}/items/page/${page}?${query}`
    )
    .then(extractData);

export const fetchInventoryMonthly = (companyId, itemId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/${itemId}/monthly?${query}`)
    .then(extractData);

export const fetchItemStatement = (companyId, itemId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/${itemId}/statement/page/${page}?${query}`)
    .then(extractData);

export const exportItemGroupWiseInventoryReport = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/items/export?${query}`)
    .then(extractData);

export const fetchInventoryGroupExport = (companyId, itemGroupId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/reports/inventory/item-group/${itemGroupId}/items/page/${page}/export?${query}`
    )
    .then(extractData);

export const printLedgerWiseReport = (companyId, payload, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/ledger-wise/print/?${query}`, payload)
    .then(extractData);
export const getStateReport = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/reports/states-wise?${query}`).then(extractData);

export const getCityWiseReport = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/cities-wise/page/${page}?${query}`)
    .then(extractData);
//gstr related
export const fetchGstr1Base = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/reports/gstr1?${query}`).then(extractData);

export const fetchGstr1Group = (companyId, reportId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/gstr1/${reportId}/page/${page}?${query}`)
    .then(extractData);

export const exportGstr1Group = (companyId, reportId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/gstr1/${reportId}/page/${page}/export?${query}`)
    .then(extractData);

export const fetchGstr1Statement = (companyId, subTypeId, reportId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/reports/gstr1/${reportId}/subtype/${subTypeId}/vouchers/page/${page}?${query}`
    )
    .then(extractData);
export const fetchSalesRegisterExport = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/sales-register/export?${query}`)
    .then(extractData);
export const fetchPurchaseRegisterExport = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/purchase-register/export?${query}`)
    .then(extractData);

export const fetchSalesPersonReport = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/report/sales-person?${query}`).then(extractData);

export const fetchVendorWiseReport = (companyId, query, pageNo) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/vendor-wise/page/:pageNo?:query`, {
      params: {
        pageNo,
        query
      }
    })
    .then(extractData);

export const exportVendorWiseReport = (companyId, query, pageNo) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/inventory/vendor-wise/page/${pageNo}/export?${query}`)
    .then(extractData);

export const fetchPartyVouchersPaymentDue = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/vouchers/payment-due/page/${page}?${query}`)
    .then(extractData);

export const exportPartyVouchersPaymentDue = (companyId, query, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/reports/vouchers/payment-due/page/${page}/export?${query}`)
    .then(extractData);
